import React from 'react'

export default ({ children }) => {
  return (
    <div>
      <h2>
         {children}
      </h2>
    </div>
  );
};
