import TitleBar from "../../../../src/components/TitleBar";
import SimpleList from "../../../../src/components/SimpleList";
import BarChart from "../../../../src/components/BarChart";
import IntroText from "../../../../src/components/IntroText";
import StackedBarChart from "../../../../src/components/StackedBarChart";
import Table from "../../../../src/components/Table";
import Box from "../../../../src/components/Box";
import Col from "../../../../src/components/Col";
import Row from "../../../../src/components/Row";
import Div from "../../../../src/components/Div";
import FileName from "../../../../src/components/FileName";
import SideNote from "../../../../src/components/SideNote";
import React from 'react';
export default {
  TitleBar,
  SimpleList,
  BarChart,
  IntroText,
  StackedBarChart,
  Table,
  Box,
  Col,
  Row,
  Div,
  FileName,
  SideNote,
  React
};